
import moment from "moment";
import L from "leaflet";
function validateEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
}
function validateHhMm(inputField) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);

    if (isValid) {
        return true;
    } else {
        return false;
    }
}

export const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);

    var dDisplay = d > 0 ? d + (" d, ") : "";
    var hDisplay = h > 0 ? h + (" h, ") : "";
    var mDisplay = m > 0 ? m + ("min") : "";
    return dDisplay + hDisplay + mDisplay;
};

export const distanceToString = (meters) => {
    if(meters)
        return `${Math.round((meters / 1000) / 1.609344)} miles (${Math.round(meters / 1000)} km)`;
    else return ''
};
export const isValidPostcode = (str) => {
    const regex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/gmi;
    return regex.test(str);
};

export const colors = {
        0: 'blue',
        1: 'green',
        2: 'red',
        3: 'yellow',
        4: 'blue',
        5: 'red',
        6: 'green',
        7: 'yellow',
        8: 'blue',
        9: 'red'
    };
function validateDate(inputField) {
    const isValid = moment(inputField, 'YYYY-MM-DD',true).isValid();
    if (isValid) {
        return true;
    } else {
        return false;
    }
}


function getLetterByNumber(number) {
    const charCodeOfA = 'A'.charCodeAt(0);
    const index = number -1;
    const charCode = charCodeOfA + index;

    if (number < 1 || number > 26) {
        return 'Wrong number';
    }

    return String.fromCharCode(charCode);
}

function createIconWithText(text) {

    const letter = getLetterByNumber(text);
    return L.divIcon({
        className: 'custom-div-icon',
        html: `<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.253 25.253C23.1329 27.3732 18.5489 32.3666 16.1592 34.9844C15.537 35.6659 14.463 35.6659 13.8408 34.9844C11.4511 32.3666 6.86707 27.3732 4.74695 25.253C-0.915651 19.5904 -0.915651 10.4096 4.74695 4.74695C10.4096 -0.915651 19.5904 -0.915651 25.253 4.74695C30.9157 10.4096 30.9157 19.5904 25.253 25.253Z" fill="#498DE2" stroke="#2377DD"/>
                  <text x="15" y="15" alignment-baseline="middle" text-anchor="middle" fill="white" font-size="16" font-family="Arial">${letter}</text>
              </svg>
              `,
        iconSize: [30, 42],
        iconAnchor: [15, 42]
    });
}

export {validateEmail, validateHhMm, validateDate, createIconWithText};